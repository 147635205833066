<template>
  <div class="container">
    <div v-if="state === 0">
      <div class="banner-tips">
        <div class="ico" />
        <div class="content">
          <span>建议用英文填写收件人信息</span>
        </div>
      </div>
      <div class="form">
        <div class="form-item">
          <div class="label">
            <div>姓名</div>
            <div class="label-eng">Contact/Recipient</div>
          </div>
          <div class="input">
            <input v-model="form.name" maxlength="30" type="text" placeholder="请输入姓名" placeholder-style="color: #bebebe;">
          </div>
        </div>
        <div class="form-item" @click="showNation">
          <div class="label">
            <div>国家/地区</div>
            <div class="label-eng">Country/Territory</div>
          </div>
          <div class="input next" :class="{'input-placeholder': !form.nation}"><span style="width: 100%;">{{ form.nation || '请选择' }}</span></div>
        </div>
        <div class="form-item">
          <div class="label" style="width: 3.75rem;">
            <div>电话</div>
            <div class="label-eng">Telephone</div>
          </div>
          <div>
            <div class="area-code" :style="{'color': originAreaCode ? '#333' : '#bebebe'}" @click="areaCodeShow = true">{{originAreaCode || '区号'}}</div>
          </div>
          <div class="down-arrow" @click="areaCodeShow = true" />
          <div class="input" style="margin-left: 0;">
            <input v-model="form.phone" type="text" placeholder="请输入联系方式" maxlength="20" placeholder-style="color: #bebebe;">
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <div>邮编</div>
            <div class="label-eng">Postal Code</div>
          </div>
          <div class="input" @click="checkNation">
            <input v-model="form.postcode" type="text" placeholder="请输入邮编" :disabled="!form.nation" placeholder-style="color: #bebebe;" @blur="getCity">
          </div>
        </div>
        <div v-if="hasCity" class="form-item">
          <span class="label">城市</span>
          <div v-if="isShowCityInput" class="input" @click="showCitys">
            <input v-model="form.city" type="text" placeholder="请输入城市" :disabled="!form.nation" placeholder-style="color: #bebebe;">
            <div v-show="!form.city" class="error-tips ft24 cff7">无法识别邮编对应的城市，请手动输入</div>
          </div>
          <div v-else class="input" :class="{'input-placeholder': !form.city, next: citys[1]}" @click="showCitys"><span>{{ form.city || '输入邮编后自动检索城市' }}</span></div>
        </div>
        <div class="form-item textarea">
          <div class="label">
            <div>详细地址</div>
            <div class="label-eng">Address</div>
          </div>
          <textarea
            v-model="form.address"
            :fit="nationShow || showCity"
            :fixed="true"
            placeholder="请输入详细地址"
            placeholder-style="font-size: 1rem;"
            class="input"
            :custom-class="alipayFixed"
            :maxlength="130"
            :custom-style="'align-self: flex-start;height: 5.625rem;' + alipayFixed"
          />
        </div>
      </div>
      <div class="form">
        <div class="form-item">
          <div class="label">
            <div>收件人类型</div>
            <div class="label-eng">Postal Code</div>
          </div>
          <div class="input flex-v">
            <div class="radio-bar flex-v" @click="recType = 1">
              <div class="radio-bar-content">
                <div class="radio-icon" :class="{ checked: recType === 1 }" />
                <div class="radio-label">个人</div>
              </div>
              <div class="radio-bar-eng">personal</div>
            </div>
            <div class="radio-bar flex-v" @click="recType = 2">
              <div class="radio-bar-content">
                <div class="radio-icon" :class="{ checked: recType === 2 }" />
                <div class="radio-label">公司</div>
              </div>
              <div class="radio-bar-eng">company</div>
            </div>
          </div>
        </div>
        <div v-if="recType === 2" class="form-item">
          <div class="label">
            <div>公司名称</div>
            <div class="label-eng">Company name</div>
          </div>
          <div class="input">
            <input v-model="form.company" type="text" maxlength="80" placeholder="请填写" placeholder-style="color: #bebebe;">
          </div>
        </div>
        <div class="form-item">
          <div class="label">
            <div>收件人邮箱</div>
            <div class="label-eng">E-mail Address</div>
          </div>
          <div class="input">
            <input v-model="form.email" type="text" maxlength="50" placeholder="选填" placeholder-style="color: #bebebe;">
          </div>
        </div>
        <div v-if="recType === 2" class="form-item">
          <div class="label">
            <div>收方税号</div>
            <div class="label-eng">Tax ID number</div>
          </div>
          <div class="input">
            <input v-model="form.comTaxNumber" type="text" maxlength="80" placeholder="提供税号确保包裹顺利寄出(选填)" placeholder-style="color: #bebebe;">
          </div>
        </div>
      </div>
      <!--倒计时-->
      <div class="time">
        <span class="time-detail">{{timeObj.hours}}</span>:
        <span class="time-detail">{{timeObj.minutes}}</span>:
        <span class="time-detail">{{timeObj.seconds}}</span>
        后链接失效
      </div>
      <div class="footer">
        <button class="button" @click="submit">保存</button>
      </div>
    </div>
    <div v-if="state === 1 || state === 2">
      <div class="success">
        <img class="success-img" :src="state === 1 ? 'https://cdn.kuaidi100.com/images/uniapp/outdate.png' : 'https://cdn.kuaidi100.com/images/rebuild/pay_success.png'" />
        <div class="success-title">{{state === 1 ? '链接已失效' : '地址提交成功'}}</div>
        <div class="success-desc">{{state === 1 ? '请让好友重新邀请填写' : '通知好友继续寄件'}}</div>
      </div>
    </div>
    <Mpopup v-model="nationShow">
      <div class="nation-wrap">
        <div class="scroll-wrap">
          <div :scroll-y="true" @scroll="handleScroll">
            <div class="wrap">
              <div class="label">热门国家/地区</div>
              <div class="hot">
                <span v-for="item in hots" :key="item.en" class="item" :class="{select: item.en === form.nation}" @click="selectNation(item)">{{ item.cn }}</span>
              </div>
            </div>
            <div class="wrap" style="padding-bottom: 0.625rem;">
              <div class="label">全部国家/地区<span class="txt">（支持{{ nationsLength }}个国家/地区）</span></div>
              <div class="search" :class="{focus: nationFocus || keyword}">
                <div v-show="!keyword" class="placeholder">
                  <img class="ico" src="https://cdn.kuaidi100.com/images/uniapp/global/ico_search.png" mode="widthFix" />输入国家/地区名进行搜索
                </div>
                <input v-model="keyword" type="text" @focus="handleFocus" @blur="handleBlur" style="z-index:0;">
                <img v-show="keyword" class="close" src="https://cdn.kuaidi100.com/images/uniapp/global/ico_close.png" mode="widthFix" @click="clearInput" />
              </div>
            </div>
            <div v-show="!nationShows[0]" class="service">
              <span>暂不支持该国家/地区，若有需要请与我们联系</span>
              <div class="strong">
                <img class="phone" src="https://cdn.kuaidi100.com/images/uniapp/global/ico_phone.png" mode="widthFit" />
                <a href="tel:0755-86719620">0755-86719620</a>
              </div>
            </div>
            <div class="list">
              <div v-for="item in nationShows" :key="item.en" class="item" :class="{select: item.en === form.nation}" @click="selectNation(item)">
                <span class="cn">{{ item.cn }}</span>
                <span class="en">（{{ item.en }}）</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Mpopup>
    <Mpopup v-model="showCity" @close="hideCity">
      <div class="city-wrap" scroll-y="true">
        <div class="title">该邮编对应多个城市，请选择目的地的城市</div>
        <div class="list">
          <div v-for="(item, index) in citys" :key="item.id" class="item" :class="{selected: cityIndex === index }" @click="selectCity(index)">
            <div class="info">{{ item | cityString }}</div>
            <div class="icon" />
          </div>
        </div>
      </div>
    </Mpopup>
    <!-- 区号 -->
    <Mpopup v-model="areaCodeShow" title="选择电话区号">
      <div class="nation-wrap">
        <div class="scroll-wrap">
          <div :scroll-y="true">
            <div class="wrap" style="padding-bottom: 0.625rem;position: absolute;z-index: 10;background-color: #fff;">
              <div class="search" :class="{focus: nationFocus || keyword}">
                <div v-show="!keyword" class="placeholder" style="z-index: 1;">
                  <img class="ico" src="https://cdn.kuaidi100.com/images/uniapp/global/ico_search.png" mode="widthFix" />输入国家/地区名进行搜索
                </div>
                <input v-model="keyword" type="text" @focus="handleFocus" @blur="handleBlur">
                <img v-show="keyword" class="close" src="https://cdn.kuaidi100.com/images/uniapp/global/ico_close.png" mode="widthFix" @click="clearInput" />
              </div>
            </div>
            <div v-show="!nationShows[0]" class="service pt90">
              <span>暂不支持该国家/地区，若有需要请与我们联系</span>
              <div class="strong">
                <img class="phone" src="https://cdn.kuaidi100.com/images/uniapp/global/ico_phone.png" mode="widthFit" />
                <a href="tel:0755-86719620">0755-86719620</a>
              </div>
            </div>
            <div class="list pt90">
              <div v-for="item in nationShows" :key="item.en" class="item" :class="{select: areaCode && (item.areaCode == areaCode) && (item.en === en)}" @click="selectAreaCode(item)">
                <span class="cn">{{ item.cn }}</span>
                <span class="en"> +{{ item.areaCode }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Mpopup>
  </div>
</template>

<script>
import Mpopup from "components/Mpopup/Mpopup"
export default {
  components: {
    Mpopup
  },
  filters: {
    cityString(city) {
      const cityArray = []
      // const keys = ['country', 'province', 'city', 'county', 'community', 'postcode']
      const keys = ['province', 'city']
      keys.map(item => {
        city[item] && cityArray.push(city[item])
      })
      return cityArray.join(',')
    }
  },
  data() {
    return {
      keyword: '',
      save: true,
      nations: [],
      showCity: false,
      nationShow: false,
      hots: [],
      sticky: false,
      form: {
        nation: '',
        name: '',
        phone: '',
        postcode: '',
        address: '',
        city: '',
        community: '',
        recordId: ''
      },
      citys: [],
      cityIndex: -1,
      hasCity: false,
      recType: 1, // 收件人类型
      areaCode: '',
      en: '', // 用于判断区号是否选中
      areaCodeShow: false,
      isShowCityInput: false,
      alipayFixed: '',
      showInvite: false,
      nationFocus: false,
      share_id: '',
      timer: null,
      timeObj: {
        hours: '',
        minutes: '',
        seconds: ''
      },
      state: ''
    }
  },
  computed: {
    nationShows() {
      const result = []
      const key = this.keyword.toLowerCase()
      this.nations.map(item => {
        (item.en.toLowerCase().indexOf(key) !== -1 || item.cn.toLowerCase().indexOf(key) !== -1) && result.push(item)
      })
      return result
    },

    originAreaCode: {
      get() {
        return this.areaCode ? '+' + this.areaCode : ''
      },
      set(val) {
        this.areaCode = '+' + val
      }
    },

    nationsLength() {
      return this.nations.length || 0
    }
  },
  watch: {
    'form.phone': function(val) {
      this.form.phone = val.replace(/[\u4E00-\u9FA5]/g, '')
    }
  },
  methods: {
    pad(num) {
      return num.toString().padStart(2, '0');
    },
    CountDown() { // 倒计时
      if (this.timeCount >= 0) {
        this.timeObj.hours = this.pad(Math.floor(this.timeCount / 3600))
        this.timeObj.minutes = this.pad(Math.floor((this.timeCount / 60) % 60))
        this.timeObj.seconds = this.pad(Math.floor(this.timeCount % 60))
        --this.timeCount;
      } else{
        clearInterval(this.timer);
      }
    },
    getInviteUrlExpire() { // 邀请链接过期时间
      this.$http.get(`perorder/internationSent/getInviteUrlExpire?shareId=${this.share_id}`).then(res => {
        this.timeCount = res.data
        if(!this.timeCount) { // 过时
          this.state = 1
        } else {
          this.state = 0
          this.timer = setInterval(() => {
            this.CountDown()
          }, 1000)
        }
      }).catch(()=> {
        this.state = 1
      })
    },
    getNations() {
      this.$http.get('/apicenter/order.do?method=countrylist').then(result => {
        this.hots = result.hot || []
        this.nations = result.data || []
        if (this.form.id && !this.areaCode) { // 兼容没填区号的旧地址
          this.getAreaCode()
        }
        this.setNation(result.data)
      })
    },
    getAreaCode() {
      const curCounty = this.form.nation
      this.areaCode = this.nations.find(item => item.en === curCounty).areaCode
    },
    setNation(data) {
      const nation = this.form.nation
      if (nation) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].en === nation && +data[i].qFlag) {
            this.hasCity = true
            this.getCity(true)
            return
          }
        }
      }
    },
    showNation() {
      this.nationShow = true
    },
    checkNation() {
      if (!this.form.nation) return this.$toast('请先输入国家/地区')
    },
    clearInput() {
      this.keyword = ''
    },
    handleFocus() {
      this.nationFocus = true
    },
    handleBlur() {
      this.nationFocus = false
    },
    showCitys() {
      if (!this.form.postcode) return this.$toast('请先输入邮编')
      if (this.citys.length < 2) return
      this.showCity = true
    },
    selectCity(index) {
      this.showCity = false
      this.cityIndex = index
      this.setCity(index)
    },
    setCity(index) {
      const city = this.citys[index]
      if (!city) return
      // this.form.city = [city.province, city.city, city.county, city.community].filter(item => {
      //   return item
      // }).join(',')
      this.form.city = city.city
      this.$set(this.form, 'recordId', city.recordId)
      this.cityIndex = index
    },
    getCity(init) {
      this.citys = []
      this.cityIndex = -1
      if (init !== true) {
        this.form.city = ''
      }
      if (!this.hasCity || !this.form.postcode) return
      this.$http.get('/apicenter/order.do?method=findIntCity', {
        data: {
          intcountry: this.form.nation,
          postcode: this.form.postcode
        },
        needAuth: true
      }).then(res => {
        const citys = res.data
        this.citys = citys
        if (citys.length === 0 && init !== true) {
          this.$toast('无法识别邮编对应的城市,请手动输入')
          this.isShowCityInput = true
        } else if (citys.length === 1) {
          this.setCity(0)
        } else {
          if (init === true) {
            citys.map((v, k) => {
              if (v.recordId === this.form.recordId) {
                return this.setCity(k)
              }
            })
          } else {
            this.showCity = true
          }
        }
      })
    },
    hideCity() {
      if (!this.form.city) this.setCity(0)
    },
    toggleSave() {
      this.save = !this.save
    },
    selectNation({ en, qFlag, areaCode }) {
      this.form.nation = en
      this.form.postcode = ''
      this.form.city = ''
      this.cityIndex = -1
      this.citys = []
      this.hasCity = +qFlag
      this.areaCode = areaCode
      this.nationShow = false
      this.en = en
    },
    selectAreaCode({ areaCode, en }) {
      this.areaCode = areaCode
      this.areaCodeShow = false
      this.en = en
    },
    handleScroll(event) {
      const scrollTop = event.detail.scrollTop * this.screenRatio
      if (scrollTop >= 288) {
        if (this.sticky === false) {
          this.sticky = true
        }
      } else {
        if (this.sticky === true) {
          this.sticky = false
        }
      }
    },
    submit() {
      const data = this.form
      const hasCity = this.hasCity && this.isShowCityInput
      // const reg = /[^a-z1-9\s\x21-\x2f\x3a-\x40\x5b-\x60\x7B-\x7F]/ig // 英文与英文符号
      const reg = /[^a-z0-9\s,-/_.]/ig // 英文与英文符号
      const phone = this.originAreaCode + '  ' + data.phone
      if (!data.name.trim()) return this.$toast('请输入1-30字的收件人姓名')
      // if (/[^a-z\s]/ig.test(data.name.trim())) return this.$toast('请使用英文填写名字')
      // if (!phone) return this.$toast('请输入联系方式')
      if (!data.nation) return this.$toast('请选择国家/地区')
      if (!data.phone.trim()) return this.$toast('请输入1-20位数的联系电话') // phone.trim().length > 20 || phone.trim().length < 5
      if (!data.postcode) return this.$toast('请输入邮编')
      if (this.hasCity && !data.city) return this.$toast('请输入正确的邮编')
      if (!data.address.trim()) return this.$toast('请输入1-130字的收件人详细地址')
      // if (reg.test(data.address)) return this.$toast('请使用英文填写详细地址')
      if (this.recType === 2 && !data.company) return this.$toast('请输入1-80字的公司名称')
      if (data.email && !/.+@.+/.test(data.email)) return this.$toast('请输入正确的50位数以内的邮箱')
      if (this.save || this.form) {
        const city = this.citys[this.cityIndex] || { city: data.city } || {}
        this.$http.post('/perorder/internationSent/saveIntAddress', {
          data: {
            shareId: this.share_id,
            intcountry: data.nation,
            intcity: city.city || '',
            // intcounty: hasCity ? city.county : '',
            intprovince: city.province || '',
            intcommunity: city.community || '',
            phone,
            intaddress: data.address.slice(0, 200),
            intpostcode: data.postcode,
            company: data.company || '',
            comTaxNumber: data.comTaxNumber || '',
            email: data.email || '',
            name: data.name,
            optType: data.id ? 0 : 1,
            id: data.id || '',
            recordId: data.recordId || ''
          },
          needAuth: true
        }).then(result => {
          this.state = 2
        }).catch(res => {
          
        })
      }
    }
  },
  mounted () {
    this.share_id = this.$route.query.share_id
    console.log(this.share_id)
    this.getNations()
    this.getInviteUrlExpire()
  }
}
</script>
<style lang="scss" scoped>
.container {
  border-bottom: 4.1875rem solid transparent;
  box-sizing: border-box;
}
.success {
  display: flex;
  flex-direction: column;
  text-align: center;
  &-img {
    width: 4rem;
    height: 4rem;
    margin: 4.375rem auto 0;
  }
  &-title {
    font-size: 1rem;
    font-weight: bold;
    padding-top: 1rem;
  }
  &-desc {
    padding-top: 0.2rem;
    font-size: 0.9rem;
  }
}
.time {
  font-size: 0.75rem;
  color: #333;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &-detail {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1px;
  }
}
.input-placeholder, .textarea-placeholder {
  color: #bebebe !important;
}
.invite {
  float: right;
  width: 6.875;
  height: 1.5rem;
  background: #6dd400;
  font-size: 0.8125rem;
  text-align: center;
  border-radius: 0.875rem;
  line-height: 1.5rem;
  margin-top: -0.1875rem;
}
.txt {
  color: #888;
}
.banner-tips {
  display: flex;
  font-size: 0.875rem;
  color: #fff;
  background: #ffa956;
  padding: 0.625rem 0.9375rem;
  align-items: baseline;
  justify-content: space-between;
	opacity: .8;
	.content {
		flex: 1;
		width: 0;
		line-height: 1.125rem;
		margin-left: 0.375rem;
	}
	.ico {
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAaCAMAAACTisy7AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAABCFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8AAACQWfHNAAAAVnRSTlMAkt8eT+VNKjproRYFQC++Xo0oSom3uW8T7S2P9yUzwfF8teEXBH3GH3DnGZULDZMVAlfqR+zEP8rPLvpZmoP4Yq0jNKOoSPRgnciyUT4i1XZqZUSgjg4jqQ4AAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAA+ElEQVQoz3WS6VbCMBBGPwEFCoiAyKIgKCAKVBTUll1WFRRZnPd/FHoorUna3j/5ztzM5OQkAMuRyw1HXORxlsd0Yql5fYfglwJ6CIZMSXQqbA/TmREjRFFtiZ3HL6REUksSpdLmxsurDLLXtCeXB25uC8yYIkpkclcWDrknhgfeVYijatRrcg145CXVAVl+QkKLz2gIsokXole8KarSKrYF2UFXVXv6aLkvyAF7k5ylk+FdkENWjngX5i865qT45JN/lZpaHtU/O7h4AVaSH59fyryXtf8k9ZZn8f1j75r61KV94+9qvR6UNvatCG23f3BiTvpnMtgBpGNWYfNXv20AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMDgtMTBUMTE6MzY6MTMrMDg6MDB9NKsQAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTA4LTEwVDExOjM2OjEzKzA4OjAwDGkTrAAAAABJRU5ErkJggg==');
		background-repeat: no-repeat;
		background-size: 0.875rem 0.8125rem;
		width: 0.875rem;
		height: 0.8125rem;
	}
}
.form {
  margin-bottom: 0.625rem;
  padding: 0 0.625rem;
  background: #fff;
}
.form-item {
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: relative;
  border-bottom: 1px solid #eee;
	.next {
		display: flex;
		align-items: center;
			&::after {
			display: inline-block;
			width: 0.375rem;
			height: 0.375rem;
			border-top: 0.09375rem solid #c4c4c4;
			border-right: 0.09375rem solid #c4c4c4;
			transform: rotate(45deg);
			content: "";
		}
	}
	.next text {
		flex: 1;
		word-break: break-all;
		width: 0;
	}
	&.textarea {
		align-items: flex-start;
		.label {
			margin-top: 0.9375rem;
    }
  }

	.label {
		width: 6.8rem;
		text-align: justify;
		// text-align-last: justify;
		color: #888;
    white-space: nowrap;
    &-eng {
      font-size: 0.6875rem;
    }
	}
  .label-small {
    width: auto;
    padding-left: 0.625rem;
  }
  .area-code {
    width: 3.65rem;
    flex: none;
    text-align: right;
    padding-right: 1.125rem;
    box-sizing: border-box;
  }
  .down-arrow {
    // position: absolute;
    // bottom: 1.3125rem;
    // left: 50%;
    transform: translateX(-0.6875rem) rotate(-45deg);
    width: 0.46875rem;
    height: 0.46875rem;
    border: {
      left: 0.09375rem solid #bebebe;
      bottom: 0.09375rem solid #bebebe;
    }
    &.expand {
      transform: translateX(-50%) rotate(135deg);
      bottom: 0.3125rem;
    }
    &::after {
      position: absolute;
      top: -0.625rem;
      left: -0.625rem;
      right: -0.625rem;
      bottom: -0.625rem;
      content: '';
    }
  }
  .flex-v {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
	.input {
		flex: 1;
		width: 0;
		margin-left: 1.25rem;
		padding: 1rem 0;
		line-height: 1rem;
    .radio-bar {
      flex-direction: column;
      &-content {
        display: flex;
        align-items: center;
      }
      &-eng {
        font-size: 0.6875rem;
        color: #888;
        width: 100%;
        text-align: left;
      }
      &:first-child {
        margin-right: 3.125rem;
      }
    }
    .radio-icon {
      min-width: 1.1875rem;
      min-height: 1.1875rem;
      margin-right: 0.8125rem;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
    .checked{
      background: url('https://cdn.kuaidi100.com/images/better/choose.png') no-repeat center;
      background-size: 100% 100%;
    }
	}
  .error-tips {
    padding-top: 0.21875rem;
  }
}
.ctrl {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.6875rem 1.3125rem 1.125rem;
	background: #efeff4;
	color: #888;
	font-size: 0.8125rem;
}
 .save {
    position: relative;
    width: 1.875rem;
    height: 1rem;
    border: 1px solid #dedede;
    background: #efeff4;
    border-radius: 0.9375rem;
    transition: all .2s;
    overflow: hidden;
    &.active {
      background: #3DDF27;
      border-color: #3DDF27;
      &::before {
        width: 0.875rem;
        height: 0.875rem;
        top: 0.09375rem;
        left: 0.9375rem;
        box-shadow: none;
      }
      &::after {
        transform: scale(0);
      }
    }
    &::before {
      position: absolute;
      left: 0;
      top: 1px;
      width: 0.9375rem;
      height: 0.9375rem;
      border-radius: 50%;
      background: #FFF;
      transition: all .3s;
      content: '';
      z-index: 1;
    }
    &::after {
      position: absolute;
      left: 0.125rem;
      top: 0;
      bottom: 0;
      right: 1px;
      background: #efeff4;
      border-radius: 0.9375rem;
      transition: all .3s;
      content: '';
    }
  }
.nation-wrap {
	position: relative;
  width: 100%;
  height: 85vh;
  box-sizing: border-box;
	background: #FFF;
	&.full {
		height: 90vh;
	}
	.wrap {
		padding: 0 0.9375rem;
	}
	.label {
		margin: 1rem 0;
		font-size: 1rem;
	}
	.scroll-wrap {
		position: relative;
		height: 100%;
	}
	scroll-view {
		width: 100%;
		height: 100%;
	}
}
.stick {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -3;
  padding-bottom: 0.625rem;
  box-sizing: border-box;
  background: #FFF;
  opacity: 0;
  transition: opacity .5s;
}
.stick.show {
  z-index: 3;
  opacity: 1;
}
.hot {
  display: flex;
  flex-wrap: wrap;
	justify-content: space-between;
	.item {
		width: 5rem;
		padding: 0 0.3125rem;
		box-sizing: border-box;
		margin-bottom: 0.625rem;
		border: 1px solid #eee;
		overflow: hidden;
		text-align: center;
		line-height: 2.0625rem;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #888;
		font-size: 0.875rem;
		&.select {
			color: #317ee7;
			border-color: #317ee7;
		}
		&:nth-child(4) {
			margin-right: 0;
		}
	}
}
.search {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19.6875rem;
  height: 2.1875rem;
  padding: 0 0.9375rem;
  border-radius: 2.1875rem;
  background: rgb(246,246,246);
	font-size: 0.875rem;
	input {
		width: 100%;
		height: 100%;
		padding-left: 0.625rem;
		box-sizing: border-box;
	}
	.placeholder {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
		color: #bebebe;
    white-space: nowrap;
	}
	.focus {
		justify-content: flex-start;
		.placeholder {
			left: 0.625rem;
			top: 50%;
			transform: translate(0,-50%);
		}
		input {
			width: 0;
			flex: 1;
		}
	}
	.ico {
		width: 0.75rem;
    margin-right: 0.3125rem;
    vertical-align: middle;
	}
	.close {
		width: 1.125rem;
	}
}
.list {
	.item {
		position: relative;
		padding: 0 0.9375rem;
		line-height: 2.5rem;
		font-size: 1rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		&.select {
			padding-right: 1.5625rem;
			background: #f2f8ff;
			&::after {
				position: absolute;
				right: 0.625rem;
				top: 0.9375rem;
				background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAiCAMAAAANmfvwAAAArlBMVEUAAAAxfuhCif9ql/8yf+g6hu0xf+gyf+gyf+kygOkxfugxf+gxfugxgOgxf+cxf+cyfugyf+gzgOo4gusxfugxf+gyf+cyfugxfugyf+gyf+kxgOgyf+czgek1gOkyguk1gekyfugyfucyfucxgOgygOk3g+oxfuf///9knu32+f70+P1fmuzd6vuOuPFDienH3PiWvfK00ParyvWewvNzp+5to+1rou1Ljuo9hejrc9hJAAAAJ3RSTlMA/QcC8w7WknxM38G1pvisc1YwFe/qzci7r4mGYEVEOiHmzsyciiVX7G8UAAABGklEQVQ4y33T2XbCIBCA4QGy74ta6951sNVq9/b9X6zBpIIE8l8kk5PvHOACUKKrWRp7ce6XLhirUgf/y6bLPtikBC+K15oIHdQjBagFBA2lVBFoLperELQUdOLRQVskbEmOpn634ukxISrjMrtn/i7eC0GuLYIfxRA1gmYW8bRDUQUQ2sT2fCh/WGB83srP8e1gEmIzV+30wfn+YBCYAUTt9M2FkULmwm03vZxMXxAGCarmSxd4A3CPquG6wDuABUpjEOgD1ER+vjZEE7gCcWrF7D814dCGlDjUHESTATFyT2Q5QApom1tFwjrCEovw5MV1p2axARnzTatol7+MNOAEDLRoMVbBQw2G2Ho2GRPEkeeHFOzRuvf3D8nlf3Lu1fuPAAAAAElFTkSuQmCC') center no-repeat;
				background-size: 1rem;
				width: 1rem;
				height: 1rem;
				border-radius: 50%;
				content: '';
			}
		}
	}
	.en {
		color: #888;
	}
}
.service {
  padding: 2.0625rem 0;
  text-align: center;
  font-size: 0.9375rem;
	color: #888;
	.strong {
		margin-top: 1.25rem;
		color: #317ee7;
	}
	.phone {
		width: 1rem;
		height: 1rem;
		margin-right: 0.3125rem;
		vertical-align: middle;
	}
}
.city-wrap {
	max-height: 15.625rem;
	.title {
		padding: 0.9375rem;
		font-size: 0.9375rem;
		color: #efeff4;
		font-weight: bold;
	}
	.item {
		display: flex;
		align-items: center;
		position: relative;
		padding: 0.625rem 0.9375rem;
		min-height: 2.375rem;
		border-bottom: 1px solid rgba(238,238,238,1);
		font-size: 1rem;
		font-weight: normal;
		hyphens: auto;
		word-break: break-all;
		text-align: justify;
		text-align-last: left;
		color: #888;
		&.selected {
			background: rgba(49,126,231,.05);
			border: none;
			color: #efeff4;
			.icon {
				background: url(https://cdn.kuaidi100.com/images/m/dispatch/select.png) right center / 1.0625rem  no-repeat;
			}
		}
		.info {
      flex: 1;
      word-break: break-all;
      white-space: normal;
		}
		.icon {
			width: 1.875rem;
			height: 1.875rem;
		}
	}
}
.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 3;
	width: 100%;
	box-sizing: border-box;
	border: 0.5625rem solid #efeff4;
	.button {
		border-radius: 0.25rem;
		background: #ff7f0f;
		outline: none;
		color: #FFF;
    line-height: 3rem;
    height: 3rem;// 支付宝此行不能省略
		text-align: center;
    font-size: 1.0625rem;
    width: 100%;
		&::after {
			display: none;
		}
	}
}
.pt90 {
  padding-top: 2.8125rem;
}

</style>
