/* eslint-disable no-undef */
import util from "lib/util"
import Region from "lib/region"

export default {
  name: "location",
  data() {
    return {
      keyword: "",
      locateInfo: {
        city: "",
        district: '',
        province: ''
      },
      autos: []
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    autofill() {
      this.autos = [];
      this.autoTime && clearTimeout(this.autoTime);
      if (!this.keyword) return;
      this.autoTime = setTimeout(() => {
        this.AutoComplete.search(this.keyword, (status, result) => {
          if (status == 'complete') {
            this.autos = result.tips
          } else {
            this.autos = []
          }
        })
      }, 500)
    },
    selectAuto(index) {
      var item = {},data = {};
      item = this.list[index];
      data = {
        position: {
          lng: item.location.lng,
          lat: item.location.lat
        },
        formattedAddress: item.name
      }
      this.geocoder.getLocation(item.district + item.address, (status, result) => {
        if (status == 'complete' && result.geocodes.length) {
          data.addressComponent = result.geocodes[0].addressComponent
          this.locateInfo = data
          this.cancel = false
          util.setLocalStorage_withTime('orderindex_location', {
            lng: data.position.lng,
            lat: data.position.lat
          })
          this.back()
        }
      })
    },
    clearInput() {
      this.focus = true;
      this.keyword = '';
      this.$refs['keyword'].focus();
    },
    search() {
      if (!this.keyword) {
        util.tips("请输入地址")
        return this.$refs.keyword.focus()
      }
      let address = this.xzqname + this.keyword
      this.geocoder.getLocation(address, (status, result) => {
        if (status == 'complete' && result.geocodes.length) {
          this.locateInfo = result.geocodes[0]
          this.cancel = false
          this.back()
        } else {
          util.tips("地址解析失败")
        }
      })
    },
    showArea() {
      var value =  [this.locateInfo.province, this.locateInfo.city, this.locateInfo.district].join(',').replace(/^,/, '')
      this.region.show(value);
    },
    locate() {
      this.relocate = true
      this.back()
    },
    toA() {
      this.navigator.switch({
        name: "courier",
      })
    }
  },
  computed: {
    xzqname: function() {
      return this.locateInfo.province + this.locateInfo.city + this.locateInfo.district
    },
    list: function() {
      return this.autos.filter(item => {
        return item.location && item.location.lng && item.location.lat
      })
    }
  },
  watch: {
    'locateInfo.city': function(val) {
      this.AutoComplete && this.AutoComplete.setCity(val)
    },
    keyword: function() {
      this.autofill()
    }
  },
  mounted() {
    this.cancel = true //默认用户会返回
    let locateInfo = this.$route.params.locateInfo
    if(locateInfo && locateInfo.city) {
      this.locateInfo = locateInfo
      this.keyword = this.$route.params.keyword
    }
    util.map.plugin(['AMap.AutoComplete', 'AMap.PlaceSearch', "AMap.Geocoder"], () => { //自动填充地址
      this.geocoder = new AMap.Geocoder()
      this.AutoComplete = new AMap.AutoComplete({
        city: this.locateInfo.city,
        citylimit: true
      })
    })
    this.region = new Region({
      level: 2,
      confirm: (result) => {
        this.locateInfo.province = result[0].name
        this.locateInfo.city = /^(11|12|31|50)/.test(result[0].code) ? result[0].name : result[1].name
        this.locateInfo.district = result.length > 2 && result[2].name ? result[2].name : result[1].name
        this.$refs['keyword'].focus()
        this.autofill()
      }
    })
  },
  beforeDestroy() {
    this.geocoder = this.AutoComplete = null
    this.autoTime && clearTimeout(this.autoTime)
    this.region.hide()
  },
  beforeRouteLeave (to, from , next) {
    if(this.cancel) this.locateInfo = {}
    to.params.locateInfo = this.locateInfo
    if(this.relocate) {
      to.params.reLocate = true
    }
    next()
  },
  // beforeRouteEnter(to, from, next) {
  //   if(!to.params.source) {
  //     next(vm => {
  //       vm.locateInfo = {}
  //       vm.navigator.replace("/")
  //     })
  //   } else {
  //     next()
  //   }
  // }
}