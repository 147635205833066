<template>
   <div class="help-container" style="background: #fff;">
    <div class="help-wrap">
      <h3>一些物品的快递建议：</h3>
      <div>
        证件建议走EMS、顺丰或京东；香烟不能超过两条；
    电池、鞭炮、蜡烛、刀具、液体、粉末、易碎品、古董、金银等贵重物品以及易坏的食品等很多快递公司不收，<strong>下单前务必联系快递员确认。</strong>
      </div>
    </div>
    <div class="help-wrap">
      <h3>以下是国家邮政总局发布的《禁止寄递物品指导目录》：</h3>
      <ul>
        <li>一、枪支（含仿制品、主要零部件）、弹药（含仿制品）</li>
        <li>二、管制器具，含各类管制刀具、弩、催泪器、催泪枪、电击器等</li>
        <li>三、爆炸物品，含爆破器材、烟花爆竹等</li>
        <li>四、压缩和液化气体及其容器，含易燃气体（包括打火机），有毒气体，易爆或者窒息、助燃气体等</li>
        <li>五、易燃液体，如汽油、柴油、煤油、桐油、丙酮、乙醚、油漆、生漆、苯、酒精、松香油等</li>
        <li>六、易燃固体、自燃物质、遇水易燃物质，如红磷、硫磺、铝粉、闪光粉、固体酒精、火柴、活性炭等</li>
        <li>七、氧化剂和过氧化物</li>
        <li>八、毒性物质</li>
        <li>九、生化制品、传染性、感染性物质</li>
        <li>十、放射性物质</li>
        <li></li>
        <li>十一、腐蚀性物质，如硫酸、硝酸、盐酸、蓄电池、氢氧化钠、氢氧化钾等</li>
        <li>十二、毒品及吸毒工具、非正当用途麻醉药品和精神药品、非正当用途的易制毒化学品</li>
        <li>十三、非法出版物、印刷品、音像制品等宣传品</li>
        <li>十四、间谍专用器材，如暗藏式窃听器材、窃照器材等</li>
        <li>十五、非法伪造物品，如伪造或者变造的货币、证件、公章等</li>
        <li>十六、侵犯知识产权和假冒伪劣物品</li>
        <li>十七、濒危野生动物及其制品，如象牙、虎骨、犀牛角及其制品等</li>
        <li>十八、禁止进出境物品</li>
        <li>十九、其他国家法律、法规禁止寄递的物品</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

	}
</script>

<style scoped>
/* pages/realname/identitydoc.wxss */
*{padding: 0;margin:0;font-family: Helvetica, Tahoma, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei";}
    li,ul{list-style: none;font-size: 16px !important;}
    li {margin-bottom: 10px;}
    .help-wrap h3 {font-size: 16px !important;color: #000;font-weight: normal;border-bottom: 1px solid #f0f0f0;padding-bottom: .5em;margin-bottom: .5em;}
    .help-wrap {font-size:16px !important;color: #888;padding: .75rem;line-height: 1.6em;}
    strong {color: #ff7f02;font-weight: normal;}

</style>